import {
	types as t,
	getParent,
	getRoot,
	flow,
	getEnv,
	resolveIdentifier,
	addDisposer
} from 'mobx-state-tree';
import moment from 'helpers/moment';
import { get, validateApi, toRem } from 'helpers/utils';
import { Program, fromApi as programFromApi } from './Program';
import { Chunk, fromApi as chunkFromApi } from './Chunk';
import { ProgramStore } from './ProgramStore';
import { Vast, fromApi as vastFromApi } from './Vast';
import { ChannelStore } from './ChannelStore';
import { PremiumTimer } from './PremiumTimer';
import { fail } from '../../ModelUtils';
import { DVRGapStore } from './DVRGapStore';
import { reaction } from 'mobx';

export const Channel = t
	.model('Channel', {
		forModel: 'Channel',
		key: t.identifier,
		id: t.string,
		slug: t.string,
		type: t.string,
		status: t.optional(t.string, 'disabled'), // ??
		thumbs: t.optional(t.string, 'disabled'), // not reliable (https://gitlab.itdc.ge/myvideo/laravel/issues/298)
		_thumbSpriteUrl: t.maybeNull(t.string),
		sort: t.optional(t.number, 0),
		global: t.optional(t.string, 'disabled'), // if channel is allowed globally
		recordingDuration: t.number, // for how long channel was recorded (in ms - we convert from secs)
		name: t.maybeNull(t.string),
		description: t.maybeNull(t.string),
		allowed: t.optional(t.boolean, false),
		packAllowed: t.optional(t.boolean, false), // if package is allowed for the given user
		rewindAllowed: t.optional(t.boolean, false),
		_logo: t.maybe(t.string),
		_currentProgram: t.maybeNull(Program), // this might not be defined and won't be updated, so do not rely on it where possible
		programStore: t.maybe(ProgramStore),
		currentChunk: t.maybeNull(Chunk),
		dvrGapStore: t.maybeNull(DVRGapStore),
		vast: t.maybe(Vast),
		showAltView: t.optional(t.boolean, false)
	})
	.volatile(self => ({
		offsetTime: Date.now(),
		isSeeking: false,
		lastSeekOn: null,
		premiumTimer: null
	}))
	.views(self => {
		const getDummyProgram = (startTime, endTime) => {
			// make sure that id for the given dummy program stays the same between regenerations
			const id = `program::${self.id}-${startTime}`;
			if (!endTime) {
				endTime = moment(startTime)
					.add(1, 'hour')
					.valueOf();
			}
			return Program.create({
				key: id,
				id,
				channelId: self.id,
				channel: self,
				name: 'პროგრამა ვერ მოიძებნა',
				startTime,
				_actualStartTime: startTime,
				_finishTime: endTime
			});
		};

		return {
			get root() {
				return getRoot(self);
			},

			get channelStore() {
				return resolveIdentifier(ChannelStore, getRoot(self), 'TvChannelStore');
			},

			serverTime() {
				const root = self.root;
				return root && root.serverTime ? root.serverTime.now() : Date.now();
			},

			get recordingBeginTime() {
				return self.serverTime() - self.recordingDuration;
			},

			get logo() {
				return 'https:' + self._logo.replace(/^https?:/, '');
			},

			get thumbSpriteUrl() {
				return 'https:' + self._thumbSpriteUrl.replace(/^https?:/, '');
			},

			get num() {
				if (self.channelStore) {
					const idx = self.channelStore.items.findIndex(
						item => item.key === self.key
					);
					return idx > -1 ? idx + 1 : -1;
				} else {
					return -1;
				}
			},

			get isSelected() {
				return self.channelStore
					? self.channelStore.selected.id === self.id
					: false;
			},

			get isFavorite() {
				return self.channelStore
					? self.channelStore.order.indexOf(self.key) !== -1
					: false;
			},

			get hasPrograms() {
				return !!(self.programStore && self.programStore.isLoaded);
			},

			hasProgramsForDay(time) {
				return self.hasPrograms
					? self.programStore.hasProgramsForDay(time)
					: false;
			},

			get currentUrl() {
				return self.currentChunk && self.currentChunk.url;
			},

			get validOffsetTime() {
				return self.showAltView && self.hasPrograms
					? self.programStore.last.actualStartTime
					: self.offsetTime;
			},

			get currentProgram() {
				return self.hasPrograms
					? self.programStore.getProgramByTime(self.offsetTime)
					: null;
			},

			get lastProgram() {
				return self.hasPrograms
					? self.showAltView
						? self.programStore.playableFinishedItems[
								self.programStore.playableFinishedItems.length - 1
						  ]
						: self.programStore.items[self.programStore.items.length - 1]
					: null;
			},

			get prevProgram() {
				if (self.hasPrograms && self.currentProgram) {
					// currentProgram might be set but overall programs not populated and vice versa
					const prevProgram = self.programStore.getPrevTo(self.currentProgram);
					return prevProgram && prevProgram.finishTime > self.recordingBeginTime
						? prevProgram
						: null;
				}
			},

			get nextProgram() {
				if (self.hasPrograms && self.currentProgram) {
					const nextProgram = self.programStore.getNextTo(self.currentProgram);
					return nextProgram && nextProgram.startTime < self.serverTime()
						? nextProgram
						: null;
				}
			},

			get canWatch() {
				return self.status !== 'disabled' && self.allowed && self.packAllowed;
			},

			get canPlay() {
				return !!(
					self.currentUrl &&
					(self.canWatch ||
						(self.premiumTimer && self.premiumTimer.hasTimeLeft))
				);
			},

			get isLive() {
				return !!(self.currentChunk && self.currentChunk.isLive);
			},

			get hasLiveStream() {
				return !self.showAltView;
			},

			get isStreamReady() {
				return !self.isSeeking && self.canPlay;
			},

			canSeek() {
				return !self.isSeeking || self.isSeekingTooLong();
			},

			areDvrGapsFetchedFor(time) {
				const store = self.dvrGapStore;
				return (
					store &&
					store.is('done') &&
					time >= store.startTime &&
					time <= store.endTime
				);
			},

			toTimeBeyondGap(time) {
				return self.dvrGapStore && self.dvrGapStore.is('done')
					? self.dvrGapStore.toTimeBeyondGap(time)
					: time;
			},

			// https://gitlab.itdc.ge/myvideo/laravel/issues/387
			isTimeInGapWithNoEnd(time) {
				return (
					self.areDvrGapsFetchedFor(time) &&
					self.dvrGapStore.inGapWithNoEnd(time)
				);
			},

			isTimeInGap(time) {
				return self.areDvrGapsFetchedFor(time) && self.dvrGapStore.inGap(time);
			},

			isTimeInNearFuture(time) {
				const now = self.serverTime();
				return moment(time).isBetween(now, moment(now).add(10, 'minutes'));
			},

			isTimeInFuture(time) {
				return time > self.serverTime();
			},

			isTimeBeyondRecordedRange(time) {
				return self.recordingBeginTime >= time;
			},

			hasRecordingForTime(time) {
				return (
					!self.isTimeInFuture(time) && !self.isTimeBeyondRecordedRange(time)
				);
			},

			isSeekingTooLong() {
				return !self.lastSeekOn || Date.now() - self.lastSeekOn > 1000 * 60;
			},

			isTimeToUpdateChunk() {
				const chunk = self.currentChunk;
				return !chunk || chunk.isOutdated();
			},

			get programsAreOutdated() {
				if (self.hasPrograms) {
					const items = self.programStore.items;
					const firstProgram = items[0];
					const lastProgram = items[items.length - 1];

					return (
						self.offsetTime < firstProgram.startTime ||
						self.offsetTime > lastProgram.finishTime
					);
				} else {
					return true;
				}
			},

			get hasThumbs() {
				return !!self._thumbSpriteUrl;
			},

			// one sprite weights around 333kb, so not exactly a perfect solution for getting a single thumb
			getBackgroundByTime(time, width = 350, height = 190) {
				const now = moment();
				if (self.hasThumbs && now.isAfter(time)) {
					const isCurrentHour = now.diff(time, 'hours', true) <= 1;
					const startOfHour = moment(time).startOf('hour');
					const dateTime = moment(time).format('YYYY-MM-DD-HH');
					const minutes = moment(time).minutes();
					// let it cache for past hours
					const spriteUrl = self.thumbSpriteUrl.replace(
						/\{([^}]+)\}/g,
						($0, $1) => {
							switch ($1) {
								case 'YYYY-MM-DD-HH':
									return dateTime;
								case 'randomString':
									return (isCurrentHour ? time : startOfHour).valueOf();
								default:
									return $0;
							}
						}
					);
					const top = Math.floor(minutes / 10) * height;
					const left = (minutes % 10) * width;
					// actual thumb size in the sprite is (was) 200 x 160
					return `url("${spriteUrl}") -${toRem(left)}rem -${toRem(
						top
					)}rem no-repeat`;
				} else {
					return 'gray';
				}
			},

			getDummyProgramsForDay(startTime, endTime = null) {
				const nextHour = startTime
					? moment(startTime).startOf('hour')
					: moment(endTime).startOf('day');
				const endHour = endTime
					? moment(endTime)
							.add(1, 'hour')
							.startOf('hour')
					: moment(startTime)
							.add(1, 'day')
							.startOf('day');

				const dummies = [];

				while (nextHour.isBefore(endHour)) {
					dummies.push(getDummyProgram(nextHour.valueOf()));
					nextHour.add(1, 'hour');
				}
				return dummies;
			},

			getDummyPatchedProgramsForDay(time) {
				const startOfDay = moment(time)
					.startOf('day')
					.valueOf();
				const endOfDay = moment(startOfDay)
					.add(1, 'day')
					.valueOf();
				const programs = self.programStore
					? self.programStore.getProgramsForDay(startOfDay)
					: null;
				if (programs) {
					// make sure that we left no gaps before the first program and after the last
					const epgStartTime = programs[0].startTime;
					const epgEndTime = programs[programs.length - 1].finishTime;
					if (epgStartTime > startOfDay) {
						programs.unshift(getDummyProgram(startOfDay, epgStartTime));
					}
					if (epgEndTime < endOfDay) {
						programs.push(getDummyProgram(epgEndTime, endOfDay));
					}
					return programs;
				} else {
					return self.getDummyProgramsForDay(startOfDay);
				}
			}
		};
	})
	.actions(self => ({
		afterAttach() {
			self.currentChunk = null;
			self.dvrGapStore = null;

			if (!self.canWatch && !self.premiumTimer) {
				self.premiumTimer = PremiumTimer.create();
			}

			// needed a way to always have an adequate value, without haphazard zeros in between
			addDisposer(
				self,
				reaction(
					() => self.currentChunk && self.currentChunk.currentTime,
					self.setOffsetTime
				)
			);
		},

		inspectDvrGaps: flow(function* inspectDvrGaps(time) {
			if (!self.areDvrGapsFetchedFor(time)) {
				// range has to be 6:00am +24h because of: https://gitlab.itdc.ge/myvideo/laravel/issues/387
				const today6am = moment()
					.hours(6)
					.minutes(0)
					.seconds(0);
				let startTime, endTime;
				if (today6am.isBefore(time)) {
					startTime = today6am.valueOf();
					endTime = moment(startTime)
						.add(24, 'hours')
						.valueOf();
				} else {
					endTime = today6am.valueOf();
					startTime = moment(endTime)
						.subtract(24, 'hours')
						.valueOf();
				}

				self.dvrGapStore = DVRGapStore.create({
					startTime,
					endTime
				});
			}
		}),

		fetchChunk: flow(function* fetchChunk(time) {
			const api = getEnv(self).api;

			self.inspectDvrGaps(time);

			if (self.canSeek()) {
				self.setSeeking(true);
				try {
					const chunk = yield api
						.channelChunk(self.id, time)
						.then(chunkFromApi);
					self.currentChunk = Chunk.create(chunk);
					return self.currentChunk;
				} catch (ex) {
					fail(self, ex);
					self.setSeeking(false);
					return null;
				}
			} else {
				fail(self, new Error(`მიდიაპლეიერი ოპერაციის შესრულების პროცესშია.`));
				return null;
			}
		}),

		fetchNextChunk: flow(function* fetchNextChunk() {
			return yield self.fetchChunk(
				self.isLive ? self.serverTime() : self.offsetTime
			);
		}),

		setSeeking(state) {
			self.isSeeking = state;
			if (!state) {
				self.lastSeekOn = Date.now();
			}
		},

		rewindBy: flow(function* rewindBy(time, unit = 'minute') {
			return self.seekTo(
				moment(self.offsetTime)
					.subtract(time, unit)
					.valueOf()
			);
		}),

		fastForwardBy: flow(function* fastForwardBy(time, unit = 'minute') {
			return self.seekTo(
				moment(self.offsetTime)
					.add(time, unit)
					.valueOf()
			);
		}),

		seekTo: flow(function* seekTo(time = null) {
			let chunk = self.currentChunk;

			if (time && self.isTimeInNearFuture(time)) {
				time = null; // treat as live
			}

			if (chunk && !chunk.isOutdated() && time) {
				// rmake sure that the given time is accessible and playable
				if (self.isTimeInFuture(time)) {
					fail(self, new Error('ამ დროის ჩანაწერები ჯერჯერობით არ არსებობს.'));
					return null;
				} else if (self.isTimeBeyondRecordedRange(time)) {
					fail(self, new Error('ამ დროის ჩანაწერები უკვე წაშლილია.'));
					return null;
				} else if (self.isTimeInGap(time)) {
					fail(
						self,
						new Error(
							'ტექნიკური ხარვეზის გამო ამ დროის მონაკვეთში ჩანაწერი არ არსებობს.'
						)
					);
					return null;
				} else if (!self.canSeek()) {
					fail(self, new Error(`მიდიაპლეიერი ოპერაციის შესრულების პროცესშია.`));
					return null;
				} else if (
					chunk &&
					chunk.isSeekable(time + 10 * 1000) && // test for +-10 sec
					chunk.isSeekable(time - 10 * 1000)
				) {
					self.setSeeking(true);
					if (self.areDvrGapsFetchedFor(time)) {
						time = self.toTimeBeyondGap(time);
					} else {
						self.inspectDvrGaps(time);
					}
					chunk.setSeekOffset(time - chunk.startTime);
					return chunk;
				}
			}
			return yield self.fetchChunk(time);
		}),

		goLive: flow(function* goLive() {
			if (!self.hasLiveStream) {
				fail(self, new Error(`მოცემულ არხს არ გააჩნია ლაივ სტრიმი!`));
				return null;
			}
			return yield self.seekTo();
		}),

		restartProgram: flow(function* restartProgram() {
			return yield self.seekTo(self.currentProgram.actualStartTime);
		}),

		loadPrevProgram: flow(function* loadPrevProgram() {
			return yield self.seekTo(self.prevProgram.actualStartTime);
		}),

		loadNextProgram: flow(function* loadNextProgram() {
			return yield self.seekTo(self.nextProgram.actualStartTime);
		}),

		setCurrentChunk(chunk) {
			self.currentChunk =
				!chunk || Chunk.is(chunk) ? chunk : Chunk.create(chunk);
		},

		setPlayBackOffset(time) {
			if (!self.isSeeking && self.currentChunk) {
				self.currentChunk.setPlayBackOffset(time);

				if (self.premiumTimer && self.premiumTimer.hasTimeLeft) {
					self.premiumTimer.dec();
				}
			}
		},

		fetchPrograms: flow(function* fetchPrograms(...args) {
			if (!self.programStore) {
				self.programStore = ProgramStore.create();
			}
			return yield self.programStore.fetchPrograms(...args);
		}),

		fetchProgramsByTime: flow(function* fetchProgramsByTime(offsetTime) {
			if (!self.programStore) {
				self.programStore = ProgramStore.create();
			}

			if (!self.programStore.is('pending')) {
				const items = yield self.programStore.fetchPrograms(
					moment(offsetTime).startOf('day'),
					moment(offsetTime).endOf('day')
				);
				return items;
			}
		}),

		setOffsetTime(time) {
			if (time) {
				self.offsetTime = time;
			}
		}
	}));

export const fromApi = data => {
	const { id, type, attributes: a = {}, relationships = {} } = data;
	const { logo, vast, currentProgram } = relationships;

	const struct = {
		key: [type, id].join('::'),
		id,
		slug: a.slug,
		type: a.type,
		status: a.status,
		thumbs: a.thumbs,
		_thumbSpriteUrl: get(
			relationships,
			'thumbs.data.attributes.template',
			null
		),
		sort: a.sort,
		global: a.global, // if channel is allowed globally
		recordingDuration: a.recordingDuration * 1000, // convert to ms
		name: a.name,
		description: a.description,
		allowed: a.allowed,
		packAllowed: a.packAllowed, // if package is allowed for the given user
		rewindAllowed: a.rewindAllowed,
		showAltView: a.hasAlternativeView === 'enabled',
		_logo: get(
			logo,
			'data.relationships.sizes.data.100x100.attributes.url',
			null
		),
		_currentProgram: currentProgram
			? Program.create(programFromApi(currentProgram.data))
			: null,
		vast: vastFromApi(get(vast, 'data.0.attributes', {}))
	};

	return validateApi(Channel, struct);
};
