import { types, flow, getEnv, getRoot } from 'mobx-state-tree';
import { floor } from 'lodash';
import { get } from 'helpers/utils';
import { Service, fromApi as serviceFromApi } from './balance/Service';
import { fail } from '../ModelUtils';

export const Balance = types
	.model('Balance', {
		balance: types.optional(types.number, 0),
		servicesStore: types.optional(types.map(Service), {})
	})
	.views(self => ({
		get userData() {
			return getRoot(self).userData;
		},

		get menu() {
			return getRoot(self).menu;
		},

		get balanceInLari() {
			return floor(self.balance / 100, 2);
		},

		get userServices() {
			return self.userData.serviceIds.map(id => self.servicesStore.get(id));
		}
	}))
	.actions(self => ({
		afterAttach: flow(function* afterAttach() {
			if (!self.servicesStore) {
				self.fetchServices();
			}

			// request balance (should be fresh every time)
			yield self.fetchBalance();
		}),

		fetchServices: flow(function* fetchServices() {
			const api = getEnv(self).api;

			try {
				const data = yield api.services();
				self.servicesStore = data.map(item => {
					const service = Service.create(serviceFromApi(item));
					return [service.id, service];
				});
			} catch (ex) {
				fail(self, ex);
				return;
			}
		}),

		fetchBalance: flow(function* fetchBalance() {
			const api = getEnv(self).api;

			try {
				const data = yield api.balance(self.id);
				self.balance = get(
					data,
					'relationships.balance.data.attributes.original',
					0
				);
			} catch (ex) {
				fail(self, ex);
				return;
			}
		})
	}));
