import { getRoot } from 'mobx-state-tree';
import { ApiError } from '../api/ApiClient';
import * as Sentry from '@sentry/browser';

export const fail = (self, err) => {
	const root = getRoot(self);
	if (root) {
		if (err instanceof ApiError) {
			// eslint-disable-next-line
			switch (err.code) {
				case ApiError.TOKEN_REFRESH_FAILED:
				case ApiError.LOCK_RESOLUTION_FAILED:
				case ApiError.USER_NOT_AUTHORIZED:
					root.setAlert(
						err.message ||
							`სესიის განახლება ვერ მოხერხდა, სისტემაში ხელახლა უნდა შეხვიდეთ.`,
						root.logout
					);
					break;

				default:
					root.setError(err);
			}
		} else {
			root.setError(err);
		}
	}
};

export const log = (...args) => {
	Sentry.withScope(scope => {
		scope.setLevel('info');
		args.forEach(arg => {
			Sentry.captureMessage(JSON.stringify(arg));
		});
	});
};
