import { assign, round, floor } from 'lodash';
import * as moment from 'moment';
import 'moment/locale/ka';

const locale = moment.localeData('ka');

moment.relativeTimeRounding(value => round(value, 5));

const relativeTime = (number, withoutSuffix, key, isFuture) => {
    // got to make it manually, 'cause moments apparatus here is faulty
    const now = moment().add(1, 'minute').valueOf(); // TODO there's some bug in moment where it fails to recognize proper startOf('day') for the time that is already at the start of day
    const startOfToday = moment(now).startOf('day');
    const endOfToday = moment(now).endOf('day');
    const hourInMs = 1000 * 60 * 60;

    const defaultOutput = () => locale._relativeTime[key].replace(/%d/i, floor(number));

    switch (key) {
        case 's':
        case 'ss':
        case 'm':
        case 'mm':
            return "დღეს";

        case 'h':
        case 'hh':
            return isFuture
                ? moment(now + hourInMs * number).startOf('day').isAfter(moment(now).startOf('day'))
                    ? "ხვალ"
                    : "დღეს"
                : moment(now - hourInMs * number).startOf('day').isBefore(moment(now).startOf('day'))
                    ? "გუშინ"
                    : "დღეს"
                    ;

        case 'd':
        case 'dd':
            if (isFuture) {
                const tillEndOfDay = round(endOfToday.diff(now, 'days', true), 5);
                const realNumber = number - tillEndOfDay;

                if (realNumber <= 1) {
                    return "ხვალ";
                } else if (realNumber <= 2) {
                    return "ზეგ";
                }
            } else {
                const intoTheDay = round(moment(now).diff(startOfToday, 'days', true), 5);
                const realNumber = number - intoTheDay;

                if (realNumber <= 1) {
                    return "გუშინ";
                } else if (realNumber <= 2) {
                    return "გუშინ წინ";
                }
            }
            return defaultOutput();

        default:
            return defaultOutput();
    }
};

moment.updateLocale('ka', {
    months : {
        standalone: 'იანვარი_თებერვალი_მარტი_აპრილი_მაისი_ივნისი_ივლისი_აგვისტო_სექტემბერი_ოქტომბერი_ნოემბერი_დეკემბერი'.split('_'),
        format: 'იანვარი_თებერვალი_მარტი_აპრილი_მაისი_ივნისი_ივლისი_აგვისტო_სექტემბერი_ოქტომბერი_ნოემბერი_დეკემბერი'.split('_'),
    },
    relativeTime: assign({}, locale._relativeTime, {
        s:  relativeTime,
        ss: relativeTime,
        m:  relativeTime,
        mm: relativeTime,
        h:  relativeTime,
        hh: relativeTime,
        d:  relativeTime,
        dd: relativeTime,
        M: relativeTime,
        MM: relativeTime,
        y: relativeTime,
        yy: relativeTime,

        future: s => s.replace(/\b(წამ|წუთ|საათ|წელ|დღ|თვ)(ი|ე)\b/, ($0, $1, $2) => $2 === 'ი' ? $1 + 'ში' : $1 + $2 + 'ში'),

        // original one lacks default return
        past: s => {
            if ((/(წამი|წუთი|საათი|დღე|თვე)/).test(s)) {
                return s.replace(/(ი|ე)$/, 'ის უკან');
            }
            if ((/წელი/).test(s)) {
                return s.replace(/წელი$/, 'წლის უკან');
            }
            return s; // <- adding this
        }
    })
});

export default moment;
