import { types as t } from 'mobx-state-tree';

export const Notification = t
	.model('Notification', {
		title: t.maybe(t.string),
		message: t.string
	})
	.volatile(self => ({
		action: null,
		onClose: null,
		onConfirm: null
	}))
	.actions(self => ({
		// @deprecated
		setAction(action) {
			self.action = action;
			self.setOnConfirm(action);
		},

		setOnConfirm(fn) {
			self.onConfirm = fn;
		},

		setOnClose(fn) {
			self.onClose = fn;
		}
	}));
