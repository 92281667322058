import { types } from 'mobx-state-tree';
import { validateApi } from 'helpers/utils';

export const Vast = types
    .model('Vast', {
        place: types.optional(types.string, 'pre-roll'), // potentially enum, possible values (pre-roll... TODO fill in the rest)
        enabled: types.optional(types.boolean, false),
        _url: types.string
    })
    .views(self => ({
        get url() {
            return self._url ? 'https:' + self._url.replace(/^https?:/, '') : null;
        }
    }));

export const fromApi = ({ place, enabled, url}) => {
    return validateApi(Vast, {
        place,
        enabled,
        _url: url
    });
}

