import { reaction } from 'mobx';
import { types, getRoot, flow, addDisposer } from 'mobx-state-tree';
import { isNumber, isString, clamp } from 'lodash';
import { waitUntil } from 'helpers/utils';
import { Tv } from './pages/Tv';
import { Dashboard } from './pages/Dashboard';
import { Balance } from './pages/Balance';

export const menuItems = [
	{
		id: 'user',
		title: 'პროფილი',
		icon: 'user-circle-o'
	},
	{
		id: 'dashboard',
		title: 'მთავარი',
		icon: 'home'
	},
	{
		id: 'tv',
		title: 'ტელევიზია',
		icon: 'tv'
	},
	{
		id: 'favorites',
		title: 'რჩეული არხები',
		icon: 'heart'
	},
	{
		id: 'balance',
		title: 'ბალანსი',
		icon: 'book'
	},
	{
		id: 'logout',
		title: 'გამოსვლა',
		icon: 'sign-out'
	}
];

export const MenuItem = types.model('MenuItem', {
	id: types.identifier,
	title: types.string,
	icon: types.maybe(types.string)
});

export const Menu = types
	.model('Menu', {
		items: types.maybe(types.array(MenuItem)),
		selected: types.maybe(types.reference(MenuItem)),
		// NOTE couldn't use types.union here, 'cause MST was continuously failing to recognize the tree as proper Auth instance
		dashboard: types.maybe(Dashboard),
		tv: types.maybe(Tv),
		balance: types.maybe(Balance)
	})
	.views(self => ({
		get root() {
			return getRoot(self);
		},

		isSelected(item) {
			return typeof item === 'string'
				? item === self.selected.id
				: item === self.selected;
		},

		findById(itemId) {
			return self.items.find(({ id }) => id === itemId);
		},

		getTitleOf(id) {
			const item = self.findById(id);
			return item ? item.title : null;
		}
	}))
	.actions(self => ({
		afterAttach() {
			if (self.selected) {
				self.select(self.selected);
			}

			addDisposer(
				self,
				reaction(
					() => self.selected,
					menuItem => {
						self.root.setLocation({ p: menuItem.id });
					}
				)
			);
		},

		select(item) {
			const slugToModel = {
				dashboard: Dashboard,
				tv: Tv,
				balance: Balance
			};

			if (isNumber(item)) {
				item = self.items[clamp(item, 0, self.items.length - 1)];
			} else if (isString(item)) {
				item = self.findById(item) || self.items[0];
			}

			switch (item.id) {
				case 'tv':
				case 'favorites':
					if (!self.tv) {
						self.tv = Tv.create();
					}
					break;

				case 'balance':
					if (!self.root.isUser) {
						self.select('tv');
						return;
					} else if (!self.balance) {
						self.balance = Balance.create();
					}
					break;

				default:
					if (!self[item.id]) {
						const Model = slugToModel[item.id];
						if (Model) {
							self[item.id] = Model.create();
						}
					}
			}

			self.selected = item;
		},

		playChannel: flow(function* playChannel(channelId = null, time = null) {
			if (self.selected !== 'tv') {
				self.select('tv');
			}

			try {
				yield waitUntil(() => self.tv.channelStore);

				// TODO could be much better as a sequence of actions, but not supported easily at the moment by MST
				const store = self.tv.channelStore;
				const channel = channelId
					? store.findChannelById(channelId)
					: store.selected || store.first;
				if (channel) {
					return yield store.isSelected(channel)
						? channel.seekTo(+time)
						: store.select(channel, time);
				}
			} catch (ex) {}
			self.tv.setChannelFailedToStartError();
		})
	}));
