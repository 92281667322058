import { reaction } from 'mobx';
import {
	types as t,
	getRoot,
	addMiddleware,
	addDisposer,
	getParentOfType,
	getParent,
	flow
} from 'mobx-state-tree';
import { debounce } from 'lodash';
import { ChannelStore } from './tv/ChannelStore';
import { HistoryStore } from './tv/HistoryStore';
import { SearchStore } from './tv/SearchStore';
import { waitUntil } from 'helpers/utils';

export const Tv = t
	.model('Tv', {
		forModel: 'Tv',
		isPlaying: t.optional(t.boolean, true),
		isStretched: t.optional(t.boolean, false),
		shouldResetPlayer: t.optional(t.boolean, false),
		overlayActive: t.maybeNull(t.string),
		channelStore: t.maybe(ChannelStore),
		historyStore: t.maybe(HistoryStore),
		searchStore: t.maybe(SearchStore)
	})
	.volatile(self => ({
		vastTimer: null,
		setWaitingDebounced: debounce(() => {
			if (
				self.currentChannel &&
				self.currentChannel.isSeeking &&
				self.isPlaying
			) {
				self.setWaiting(true);
			}
		}, 1500),
		isTimeToShowVast: false,
		isVastPlaying: false,
		isWaiting: false,
		currentUrl: null
	}))
	.views(self => ({
		get root() {
			return getRoot(self);
		},

		get idleCounter() {
			return self.root.idleCounter;
		},

		get isStreamReady() {
			return self.currentChannel && self.currentChannel.isStreamReady;
		},

		get canSeek() {
			return self.isStreamReady && self.currentChannel.canSeek;
		},

		get isTvPageActive() {
			return getParent(self).isSelected('tv');
		},

		get isOverlayActive() {
			return !!self.overlayActive;
		},

		get shouldShowAltView() {
			return self.currentChannel && self.currentChannel.showAltView;
		},

		get currentChannel() {
			return self.channelStore ? self.channelStore.selected : null;
		},

		get currentProgram() {
			return self.currentChannel ? self.currentChannel.currentProgram : null;
		}
	}))
	.actions(self => {
		let lastOverlay = null;

		return {
			afterAttach: flow(function* afterAttach() {
				if (!self.channelStore) {
					self.channelStore = ChannelStore.create();
				}

				if (!self.historyStore) {
					self.historyStore = HistoryStore.create();
				}

				if (!self.searchStore) {
					self.searchStore = SearchStore.create();
				}

				// monitor changes in global channels store
				addDisposer(
					self,
					reaction(
						() => self.root.channels,
						channels => {
							self.channelStore =
								channels && channels.size ? ChannelStore.create() : undefined;
						}
					)
				);

				addDisposer(
					self,
					reaction(
						() => self.overlayActive,
						overlayActive => {
							if (self.isTvPageActive) {
								const query = {
									p: 'tv',
									o: overlayActive ? overlayActive : undefined
								};
								self.root.setLocation(query);
							}
						}
					)
				);

				addDisposer(
					self,
					reaction(
						() => self.currentProgram,
						currentProgram => {
							if (currentProgram) {
								const query = {
									p: 'tv',
									n: currentProgram.channel.slug,
									c: currentProgram.channelId,
									// every val should be string or query strings won't match
									t: `${currentProgram.actualStartTime}`,
									o: self.overlayActive ? self.overlayActive : undefined
								};

								self.root.setLocation(query);
							}
						}
					)
				);

				addDisposer(
					self,
					reaction(
						() => self.idleCounter.tensecs,
						tensecs => {
							if (tensecs) {
								if (
									self.overlayActive &&
									self.overlayActive === 'controls' &&
									self.isPlaying
								) {
									self.hideOverlay();
								}
							}
						}
					)
				);

				addDisposer(
					self,
					reaction(
						() => !self.currentChannel || self.currentChannel.offsetTime,
						offsetTime => {
							self.setWaiting(false);
							self.setWaitingDebounced();
						}
					)
				);

				addDisposer(
					self,
					reaction(
						() => self.currentChannel && self.currentChannel.offsetTime,
						offsetTime => {
							if (
								self.isStreamReady &&
								offsetTime &&
								!self.currentChannel.hasProgramsForDay(offsetTime)
							) {
								self.currentChannel.fetchProgramsByTime(offsetTime);
							}
						},
						{ delay: 3000 }
					)
				);

				addDisposer(
					self,
					reaction(
						() => self.currentProgram,
						currentProgram => {
							if (currentProgram && self.isStreamReady && self.historyStore) {
								// keep track of programs being watched in history
								self.addToHistory(currentProgram);
							}
						},
						{ delay: 1000 * 10 }
					)
				); // TODO if there ever be a config, this might be added to it

				addDisposer(
					self,
					addMiddleware(self.root.channels, (call, next, abort) => {
						if (
							['seekTo'].indexOf(call.name) !== -1 &&
							'action' === call.type
						) {
							if (self.isTimeToShowVast) {
								// wait until stream is ready, otherwise HLSPlayer.tizen might hang
								waitUntil(() => self.isStreamReady)
									.then(() => self.setVastIsPlaying(true))
									.catch(self.hideVast);
							}
						}
						return next(call);
					})
				);

				// needed a way to always have an adequate value, without haphazard nulls in between
				addDisposer(
					self,
					reaction(
						() => self.currentChannel && self.currentChannel.currentUrl,
						url => {
							if (url) {
								self.setCurrentUrl(url);
							}
						}
					)
				);

				setImmediate(async () => {
					await self.channelStore.select();
					self.setTimeToShowVast(true);
				});
			}),

			beforeDestroy() {
				if (self.vastTimer) {
					clearTimeout(self.vastTimer);
				}
				self.setWaitingDebounced.cancel();
			},

			play: flow(function* play() {
				if (self.currentChannel && self.currentChannel.isTimeToUpdateChunk()) {
					const chunk = yield self.currentChannel.fetchNextChunk();
					if (!chunk) {
						return; // fetchNextChunk() will throw a warning popup for failure
					}
				}
				self.isPlaying = true;
			}),

			pause() {
				self.isPlaying = false;
			},

			pauseLive() {
				self.isPlaying = false;

				// if user pauses the live stream, it is not live anymore, so get the regular chunk to continue from
				const channel = self.channelStore.selected;
				if (channel && channel.isLive) {
					channel.seekTo(channel.offsetTime - 2000);
				}
			},

			playPause: flow(function* playPause() {
				self.isPlaying ? self.pause() : yield self.play();
			}),

			setWaiting(state) {
				self.isWaiting = state;
			},

			stretch() {
				self.isStretched = true;
			},

			shrink() {
				self.isStretched = false;
			},

			stretchShrink() {
				self.isStretched = !self.isStretched;
			},

			setTimeToShowVast(state) {
				self.isTimeToShowVast = state;
			},

			setVastIsPlaying(state) {
				self.isVastPlaying = state;
			},

			setShouldResetPlayer(state) {
				self.shouldResetPlayer = state;
			},

			resetPlayer() {
				self.setShouldResetPlayer(true);
				setTimeout(() => {
					self.setShouldResetPlayer(false);
				}, 500);
			},

			resetVastTimer() {
				self.setTimeToShowVast(false);
				self.vastTimer = setTimeout(() => {
					self.setTimeToShowVast(true);
				}, 1000 * 60 * 10); // 10 mins
			},

			addToFavorites(channel) {
				self.channelStore && self.channelStore.addToFavorites(channel);
			},

			addToHistory(program) {
				if (program) {
					const { channel, id: programId, name: programName } = program;
					if (channel) {
						const { id, logo, name, offsetTime } = channel;
						self.historyStore.add({
							channelId: id,
							channelLogo: logo,
							channelName: name,
							time: offsetTime,
							programId,
							programName
						});
					}
				}
			},

			showOverlay(controlId) {
				lastOverlay = self.overlayActive;

				if (controlId === 'channel-info') {
					setTimeout(() => {
						if (self.overlayActive === 'channel-info') {
							self.hideOverlay();
						}
					}, 2000);
				}

				self.overlayActive = controlId;
			},

			hideOverlay(restoreLast = false) {
				if (restoreLast) {
					self.showOverlay(lastOverlay);
				} else {
					self.overlayActive = null;
				}
				lastOverlay = null;
			},

			hideVast() {
				self.setVastIsPlaying(false);
				self.resetVastTimer();

				if (self.currentChannel.isLive) {
					self.currentChannel.goLive();
				} else {
					self.play();
				}
			},

			setChannelFailedToStartError() {
				let isLive = true;
				if (self.currentChannel) {
					isLive = self.currentChannel.isLive;
					self.currentChannel.setCurrentChunk(null);
				}

				if (isLive) {
					const notification = self.root.setError(
						`მოცემული არხის ჩართვა ვერ მოხერხდა. ვცადოთ თავიდან?`,
						async () => {
							self.root.clearNotification();

							const channel = await self.channelStore.select(
								self.currentChannel
							);
							if (!channel) {
								self.setChannelFailedToStartError();
							}
						}
					);
					// override onClose handler to show epg1 overlay instead of simply closing
					if (notification) {
						notification.setOnClose(() => {
							self.showOverlay('epg1');
							// let the modal capture the focused epg1 element before closing
							// so that it can return the focus to it after that
							setImmediate(() => {
								self.root.clearNotification();
							});
						});
					}
				} else {
					self.root.setError(`მოცემული დროით პროგრამის ჩართვა შეუძლებელია.`);
				}
			},

			setCurrentUrl(url) {
				self.currentUrl = url;
			}
		};
	});
