import {
	types,
	isRoot,
	getRoot,
	hasParentOfType,
	getParentOfType
} from 'mobx-state-tree';
import { assign, ceil } from 'lodash';
import { guid, validateApi, toRem } from 'helpers/utils';
import moment from 'helpers/moment';
import { Channel } from '../../pages/tv/Channel';
import { ProgramStore } from './ProgramStore';

export const Program = types
	.model('Program', {
		key: types.identifier, // do not use id as identifier since identifier has to be unique across the whole tree
		id: types.string,
		channelId: types.string,
		channel: types.maybe(types.reference(types.late(() => Channel))),
		name: types.maybe(types.string),
		startTime: types.number,
		_actualStartTime: types.number,
		_finishTime: types.maybe(types.number), // might be null, not yet sure when and why, but we can't allow that
		_actualFinishTime: types.maybeNull(types.number),
		screen: types.maybeNull(types.string)
	})
	.views(self => ({
		get channelKey() {
			return ['Channel', self.channelId].join('::');
		},

		serverTime() {
			// when Program is instantiated as dummy, there won't be any root node to refer to
			return !isRoot(self)
				? getRoot(self).serverTime.now()
				: moment().valueOf();
		},

		get actualStartTime() {
			// for whatever reason actualStartTime sometimes might be out of range and it causes all kind of weird problems
			// https://gitlab.itdc.ge/myvideo/laravel/issues/361
			return moment(self._actualStartTime).isBetween(
				self.startTime,
				self.finishTime,
				null,
				'[)'
			)
				? self._actualStartTime
				: self.startTime;
		},

		get finishTime() {
			// cannot rely on _finishTime: https://gitlab.itdc.ge/myvideo/laravel/issues/361#note_47214
			// if (self._finishTime) {
			//     return self._finishTime;
			// } else
			if (hasParentOfType(self, ProgramStore)) {
				// TODO log if _finishTime is null
				const programStore = getParentOfType(self, ProgramStore);
				const nextProgram = programStore.getNextTo(self);
				// we cannot let it to be null, so pretend that it lasts till the end of that day
				return nextProgram
					? nextProgram.startTime
					: moment(self.startTime)
							.startOf('day')
							.add(1, 'day')
							.valueOf();
			} else {
				return moment(self.startTime)
					.startOf('day')
					.add(1, 'day')
					.valueOf();
			}
		},

		get actualFinishTime() {
			return moment(self._actualFinishTime).isBetween(
				self.startTime,
				self.finishTime,
				null,
				'[)'
			)
				? self._actualFinishTime
				: self.finishTime;
		},

		get startHour() {
			return moment(self.actualStartTime).format('HH:mm');
		},

		get endHour() {
			return moment(self.actualFinishTime).format('HH:mm');
		},

		get timeSpan() {
			return [self.startHour, self.endHour].join(' - ');
		},

		get duration() {
			return self.actualFinishTime - self.actualStartTime;
		},

		get durationInMins() {
			return ceil(self.duration / 1000 / 60, 2);
		},

		get offsetTime() {
			const channel = self.channel;
			return Math.max(
				self.actualStartTime,
				Math.min(
					self.actualFinishTime,
					channel ? channel.offsetTime : self.serverTime()
				)
			);
		},

		get aired() {
			const serverTime = self.serverTime();
			return moment(serverTime).diff(self.actualStartTime, 'days') > 2
				? moment(self.actualStartTime).format('DD MMM')
				: moment(serverTime).to(self.actualStartTime);
		},

		get spansAcrossMidnight() {
			const startOfDay = moment(self.actualFinishTime).startOf('day');
			return startOfDay.isAfter(self.actualStartTime);
		},

		get isFromFuture() {
			return self.actualStartTime > self.serverTime();
		},

		get isFromDistantPast() {
			return (
				!self.channel ||
				self.actualStartTime <
					self.serverTime() - self.channel.recordingDuration
			);
		},

		get isLive() {
			const serverTime = self.serverTime();
			return (
				self.actualStartTime >= serverTime && serverTime <= self.finishTime
			);
		},

		get isDisabled() {
			return self.isFromFuture || self.isFromDistantPast;
		},

		get percentsPlayed() {
			return Math.min(
				ceil(
					((self.offsetTime - self.actualStartTime) / self.duration) * 100,
					2
				),
				100
			);
		},

		get channelLogo() {
			return self.channel ? self.channel.logo : null;
		},

		get hasThumb() {
			return !!(self.channel && self.channel.hasThumbs);
		},

		get background() {
			return self.getScreenStyles(430, 236);
		},

		getScreenStyles(width, height) {
			const backgroundStyles = self.screen
				? { background: `url("${self.screen}") no-repeat` }
				: self.channel && self.channel.hasThumbs
				? {
						background: self.channel.getBackgroundByTime(
							self.actualStartTime,
							width,
							height
						),
						backgroundSize: '1000% 600%'
				  }
				: { backgroundColor: 'transparent' };

			return assign(
				{
					width: `${toRem(width)}rem`,
					height: `${toRem(height)}rem`
				},
				backgroundStyles
			);
		}
	}))
	.actions(self => ({
		afterAttach() {
			if (!self.channel) {
				if (hasParentOfType(self, Channel)) {
					self.channel = getParentOfType(self, Channel);
				} else {
					const channel = getRoot(self).channels.get(self.channelKey);
					if (channel) {
						self.channel = channel;
					}
				}
			}
		}
	}));

export const fromApi = data => {
	// {
	//     "type": "Program",
	//     "id": "45569e80-1ec1-11e8-bf37-d1816a494188",
	//     "attributes": {
	//         "importerId": "118228464",
	//         "channelId": "086475f0-f76f-11e7-9832-8bbabc39ab0b",
	//         "importer": "myvideo",
	//         "startTime": "2018-03-04 03:20:00",
	//         "actualStartTime": "2018-03-04 03:20:00",
	//         "finishTime": "2018-03-04 04:20:00",
	//         "name": "წიგნების თარო“",
	//         "description": "Description with Seeder For prog with  OLD ID =   118228464",
	//         "additionalData": null,
	//         "updatedBy": null,
	//         "screen": "http://www.myvideo.ge/?CIA=1&ci_c=flsstreams&ci_d=services&ci_m=thumb&chan=mnogotv&date=2018-03-24+10%3A02%3A00"
	//     },
	//     "relationships": []
	// }
	const { id, attributes: a = {} } = data;
	const timestamp = date =>
		date ? moment(date, 'YYYY-MM-DD HH:mm:ss').valueOf() : null;
	const struct = {
		key: `program::${id}-${timestamp(a.actualStartTime)}`,
		id,
		channelId: a.channelId,
		name: a.name,
		startTime: timestamp(a.startTime),
		_actualStartTime: timestamp(a.actualStartTime),
		_finishTime: timestamp(a.finishTime),
		_actualFinishTime: timestamp(a.actualFinishTime),
		screen: a.screen
	};

	return validateApi(Program, struct);
};
