import { types as t, getRoot } from 'mobx-state-tree';
import { validateApi } from 'helpers/utils';
import moment from 'helpers/moment';

export const User = t
	.model('User', {
		id: t.identifier,
		username: t.string,
		email: t.string,
		gender: t.enumeration('Gender', ['male', 'female']),
		birthDate: t.number,
		status: t.number,
		loginIp: t.string,
		_avatarUrl: t.maybe(t.string),
		facebookId: t.maybeNull(t.number),
		serviceIds: t.optional(t.array(t.number), [])
	})
	.views(self => ({
		get root() {
			return getRoot(self);
		},

		get hasServices() {
			return !!self.serviceIds.length;
		},

		get avatarUrl() {
			const isImage = url => url && /\.(jpe?g|png|gif)$/.test(url);
			return isImage(self._avatarUrl)
				? 'https:' + self._avatarUrl.replace(/^https?:/, '')
				: null;
		}
	}));

export const fromApi = data => {
	const {
		id,
		attributes: {
			username,
			email,
			gender,
			birthDate,
			status,
			loginIp,
			avatar: _avatarUrl,
			facebookId
		}
	} = data;

	const struct = {
		id: `${id}`,
		username,
		email,
		gender,
		birthDate: moment(birthDate, 'YYYY-MM-DD').valueOf(),
		status,
		loginIp,
		_avatarUrl,
		facebookId
	};

	return validateApi(User, struct);
};
