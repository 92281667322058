import { types, destroy } from "mobx-state-tree";
import moment from 'helpers/moment';

const HistoryItem = types
    .model("HistoryItem", {
        channelId: types.string,
        channelLogo: types.maybe(types.string),
        channelName: types.maybe(types.string),
        time: types.number,
        programId: types.string,
        programName: types.string,
    })
    .views(self => ({
        get id() {
            return [self.channelId, self.programId].join('-');
        },

        get watchTime() {
            const time = moment(self.time);
            return time.isSame(moment(), 'day') ? time.format('HH:mm') : time.format('ddd DD | HH:mm');
        }
    }))

export const HistoryStore = types
    .model("HistoryStore", {
        items: types.optional(types.array(HistoryItem), [])
    })
    .views(self => ({
        get hasItems() {
            return self.items ? self.items.length > 0 : false;
        },

        findIndex(item) {
            return self.items.findIndex(({ channelId, programId }) =>
                item.channelId === channelId && item.programId === programId
            )
        }
    }))
    .actions(self => ({
        add(data) {
            const idx = self.findIndex(data);

            // if there's the same item deeper in history, remove it
            if (idx > 0) {
                self.items.splice(idx, 1);
            }

            // unless idx is of the last item in history, add new item
            if (idx !== 0) {
                self.items.unshift(data);
            }

            if (self.items.length > 30) { // TODO this can become configurable
                self.items.length = 30;
            }
        },

        remove(item) {
            destroy(typeof item === 'number' ? self.items[item] : item);
        }
    }));
