import { types } from 'mobx-state-tree';
import { get, validateApi } from 'helpers/utils';
import { Package, fromApi as packageFromApi } from './Package'

export const Service = types
    .model('Service', {
        id: types.string,
        name: types.string, // maybe this could be enumeration
        packages: types.optional(types.array(Package), [])
    });

export const fromApi = data => {
    const { id, attributes: a = {}, relationships: r = {} } = data;

    const packages = get(r, 'packages.data', [])
        .map(data => Package.create(packageFromApi(data)));

    const struct = {
        id,
        name: a.name,
        packages
    }

    return validateApi(Service, struct);
}
