import { types } from 'mobx-state-tree';
import { DashboardSection } from './DashboardSection';
import { Channel } from '../tv/Channel';


export const ChannelSection = DashboardSection
    .named("ChannelSection")
    .props({
        forModel: 'ChannelSection',
        items: types.optional(types.array(types.reference(Channel)), [])
    });
