import { types } from 'mobx-state-tree';
import { throttle } from 'lodash';
import moment from 'helpers/moment';

const TOTAL_TIME = 1000 * 60; // 1 min
const RESET_INTERVAL = 4 * 60 * 60 * 1000;

export const PremiumTimer = types
    .model('PremiumTimer', {
        timeRemaining: types.optional(types.number, TOTAL_TIME), // 1 min
        lastResetTime: types.optional(types.number, Date.now()),
    })
    .views(self => ({
        get hasTimeLeft() {
            return self.timeRemaining > 0
        },

        get timeSpent() {
            return TOTAL_TIME - self.timeRemaining;
        },

        get percentSpent() {
            return Math.min(self.timeSpent / TOTAL_TIME * 100, 100);
        }
    }))
    .volatile(self => ({
        dec: null
    }))
    .actions(self => {
        let timer;
        return {
            afterCreate() {
                self.dec = throttle(self._dec, 1000); // make it testable
            },

            afterAttach() {
                if (!self.dec) {
                    self.dec = throttle(self._dec, 1000);
                }

                if (moment().diff(self.lastResetTime) > RESET_INTERVAL) {
                    self.reset();
                } else {
                    timer = setTimeout(
                        self.reset,
                        RESET_INTERVAL - moment().diff(self.lastResetTime)
                    );
                }
            },

            beforeDestroy() {
                if (timer) {
                    clearTimeout(timer);
                }
            },

            _dec() {
                if (self.hasTimeLeft) {
                    self.timeRemaining = Math.max(0, self.timeRemaining - 1000)
                }
            },

            reset() {
                self.timeRemaining = 1000 * 60;
                self.lastResetTime = Date.now();

                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(self.reset, RESET_INTERVAL); // 4 hours
            }
        }
    })
