import {
	types,
	flow,
	getParent,
	getEnv,
	getRoot,
	isAlive
} from 'mobx-state-tree';
import { validateApi } from 'helpers/utils';
import moment from 'helpers/moment';
import { fail } from '../../ModelUtils';

export const DVRGap = types.model('DVRGap', {
	fromTime: types.number,
	toTime: types.number,
	duration: types.number
});

export const DVRGapStore = types
	.model('DVRGapStore', {
		startTime: types.number, // range is required to request gaps for
		endTime: types.number,
		state: types.optional(
			types.enumeration('State', ['idle', 'pending', 'done', 'error']),
			'idle'
		),
		items: types.maybe(types.array(DVRGap))
	})
	.views(self => ({
		is: (...args) => args.indexOf(self.state) !== -1,

		get channel() {
			return getParent(self);
		},

		serverTime() {
			return self.channel.serverTime();
		},

		inGap(time) {
			return (
				self.items.findIndex(
					item =>
						time >= item.fromTime &&
						time <= item.toTime &&
						item.toTime <= self.serverTime() // ignore invalid gaps
				) > -1
			);
		},

		// for chunks that are close to the live boundary, whole chunk beyond the current time will be a gap
		// this is actually a bug: https://gitlab.itdc.ge/myvideo/laravel/issues/387
		inGapWithNoEnd(time) {
			return (
				self.items.findIndex(
					item =>
						time >= item.fromTime &&
						time <= item.toTime &&
						item.toTime > self.serverTime()
				) > -1
			);
		},

		toTimeBeyondGap(time) {
			const gap = self.items.find(
				item => time >= item.fromTime && time <= item.toTime
			);
			return gap ? gap.toTime + 2000 : time; // play safe by adding two extra secs to outmost boundary of the gap
		}
	}))
	.actions(self => ({
		afterAttach: flow(function* afterAttach() {
			if (self.is('idle')) {
				return yield self.fetchDVRGaps();
			} else if (self.is('pending')) {
				self.state = 'idle';
			}
		}),

		fetchDVRGaps: flow(function* fetchDVRGaps() {
			const api = getEnv(self).api;
			const channelId = self.channel.id;

			if (!self.is('pending')) {
				self.state = 'pending';

				try {
					const items = yield api
						.dvrGaps(channelId, self.startTime, self.endTime)
						.then(fromApi);

					if (isAlive(self)) {
						self.state = 'done';
						self.items = items.map(item => DVRGap.create(item));
					}

					return items;
				} catch (ex) {
					if (isAlive(self)) {
						self.state = 'idle';
					}
					fail(self, ex);
					return;
				}
			}
		})
	}));

export const fromApi = data => {
	const { attributes = {} } = data;
	const { ranges = [] } = attributes;

	const toTimestamp = time =>
		time ? moment(time, 'YYYY-MM-DD HH:mm:ss').valueOf() : null;

	return ranges.map(range => {
		const { from, to, duration } = range;

		const struct = {
			fromTime: toTimestamp(from),
			toTime: toTimestamp(to),
			duration: duration * 1000
		};

		return validateApi(DVRGap, struct);
	});
};
