import { stringify } from 'querystring';
import { assign, isEmpty, trim } from 'lodash';
import Axios from 'axios';
import Env from '../Env';

export class ApiError extends Error {
	static API_ERROR = 'API_ERROR';
	static HTTP_ERROR = 'API_ERROR_HTTP_ERROR';
	static CREDENTIALS_MISSING = 'API_ERROR_CREDENTIALS_MISSING';
	static LOCK_RESOLUTION_FAILED = 'API_ERROR_LOCK_RESOLUTION_FAILED';
	static RETRY_FAILED = 'API_ERROR_RETRY_FAILED';
	static REFRESH_TOKEN_MISSING = 'API_ERROR_REFRESH_TOKEN_MISSING';
	static TOKEN_REFRESH_FAILED = 'API_ERROR_TOKEN_REFRESH_FAILED';

	static defaultMessage =
		'სესიის განახლება ვერ მოხერხდა, სისტემაში ხელახლა უნდა შეხვიდეთ.';

	static defaultMessages = Object.assign(
		{
			[ApiError.LOCK_RESOLUTION_FAILED]: ApiError.defaultMessage,
			[ApiError.REFRESH_TOKEN_MISSING]: ApiError.defaultMessage,
			[ApiError.TOKEN_REFRESH_FAILED]: ApiError.defaultMessage,
			[ApiError.API_ERROR]: 'სერვისი ამჟამად მიუწვდომელია. სცადეთ მოგვიანებით.',
			[ApiError.RETRY_FAILED]:
				'სერვისი ამჟამად მიუწვდომელია. სცადეთ მოგვიანებით.'
		},
		MediaError.defaultMessages
	);

	constructor(code, message = null) {
		super(ApiError.defaultMessages[code] || ApiError.defaultMessage);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ApiError);
		}

		this.code = code;
	}
}

export class ApiClient {
	constructor(cfg = {}) {
		this._cfg = cfg;
		this.maxRetries = 5;
		this.ua =
			'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36';

		this._defaultOpts = {
			baseUrl: 'http://localhost:4001',
			method: 'get',
			requiresAuth: false
		};

		this._locked = false;
		this._processing = false;
		this._resolvingLock = false;
		this._queue = [];
		this._retryCounter = 0;

		this._defaultNoAuthHeaders =
			Env.platform === 'tizen'
				? {
						'smartv-keyhash': dotenv.REACT_APP_SMART_KEYHASH
				  }
				: {};

		this._defaultAuthHeaders =
			Env.platform === 'tizen'
				? {
						'smartv-keyhash': dotenv.REACT_APP_SMART_KEYHASH
				  }
				: {};
	}

	setOption(name, value) {
		this._cfg[name] = value;
	}

	getOption(name) {
		return this._cfg[name];
	}

	_api(opts, params = {}, headers = {}) {
		if (typeof opts === 'string') {
			opts = { endpoint: opts };
		}

		/* tslint:disable-next-line:align */
		let _opts = assign({}, this._defaultOpts, opts);

		if (_opts.requiresAuth && !this._cfg) {
			return Promise.reject(new ApiError(ApiError.CREDENTIALS_MISSING));
		} else {
			let config = this._generateConfig(_opts, params, headers);
			if (this._locked && _opts.requiresAuth) {
				return new Promise((resolve, reject) => {
					this._queue.push({
						config,
						resolve,
						reject
					});
				});
			} else {
				return this._request(config)
					.then(res => this._handleSuccess(res))
					.catch(res => this._handleFailure(res, _opts.requiresAuth));
			}
		}
	}

	// has to be overriden in child classes to do something useful
	_resolveLock() {
		return Promise.resolve();
	}

	_handleSuccess(res) {
		if (res.status >= 200 && res.status < 400) {
			return res.data;
		} else {
			return Promise.reject(
				new ApiError(
					ApiError.HTTP_ERROR,
					`Request failed with status ${res.status}`
				)
			);
		}
	}

	_handleFailure(err, requiresAuth = true) {
		const res = err.response;
		const getErrorMessage = () => {
			if (res) {
				if (res.error) {
					return res.error;
				} else if (res.data && res.data.message) {
					return res.data.message;
				}
			}
			return err.message;
		};

		if (res && res.status === 401 && requiresAuth) {
			this._locked = true;

			if (++this._retryCounter > this.maxRetries) {
				this._lockReset();
				return Promise.reject(
					new ApiError(
						ApiError.RETRY_FAILED,
						`Request repeatedly failed ${this.retryCounter} times with status ${
							res.status
						}`
					)
				);
			}

			return new Promise((resolve, reject) => {
				this._queue.push({
					config: res.config,
					resolve,
					reject
				});

				if (!this._resolvingLock) {
					this._resolvingLock = true;
					this._resolveLock()
						.then(() => {
							this._process();
						})
						.catch(() => {
							reject(
								new ApiError(
									ApiError.LOCK_RESOLUTION_FAILED,
									`Lock resolution has failed!`
								)
							);
						})
						.finally(() => {
							this._resolvingLock = false;
							this._lockReset();
						});
				}
			});
		} else {
			return Promise.reject(
				new ApiError(ApiError.API_ERROR, getErrorMessage())
			);
		}
	}

	_process() {
		if (this._processing) {
			return;
		}
		this._processing = true;

		let req = this._queue.shift();
		if (req) {
			let { config, resolve, reject } = req;

			// update headers with current values
			assign(config.headers, this._defaultAuthHeaders);

			this._request(config)
				.then(res => {
					this._processing = false;
					setImmediate(() => {
						this._process();
					});
					resolve(res.data);
				})
				.catch(err => {
					this._processing = false;
					reject(err);
				});
		} else {
			this._processing = false;
		}
	}

	_generateConfig(opts, params = {}, headers = {}) {
		let url = [opts.baseUrl, opts.endpoint]
			.map(str => trim(str, '/'))
			.join('/');
		let queryStr = stringify(params);
		let data;

		if (opts.method === 'get') {
			url += !isEmpty(queryStr) ? '/?' + queryStr : '';
		} else {
			data = params;
		}

		if (opts.requiresAuth) {
			assign(headers, this._defaultAuthHeaders);
		} else {
			assign(headers, this._defaultNoAuthHeaders);
		}

		return {
			url,
			headers,
			method: opts.method,
			data
		};
	}

	_request(config) {
		return Axios.request(config);
	}

	_lockReset() {
		this._queue.length = 0;
		this._locked = false;
		this._retryCounter = 0;
	}
}
