import * as Sentry from '@sentry/browser';
import { types as t, flow, getEnv, getRoot } from 'mobx-state-tree';
import { fail } from './ModelUtils';

export const ServerTime = t
	.model('ServerTime', {
		offset: t.optional(t.number, 0),
		timezone: t.optional(t.string, 'Asia/Tbilisi')
	})
	.views(self => ({
		now() {
			return Date.now() - self.offset;
		}
	}))
	.volatile(self => ({
		serverTimeSyncTimer: null
	}))
	.actions(self => ({
		afterAttach: flow(function*() {
			// autosync server time once in a while (TODO: move this to house keeper script when it's ready)
			self.serverTimeSyncTimer = setInterval(() => {
				if (getRoot(self).isAuthorized) {
					self.fetchServerTime();
				}
			}, 30 * 1000 * 60); // every half an hour
		}),

		beforeDestroy() {
			if (self.serverTimeSyncTimer) {
				clearInterval(self.serverTimeSyncTimer);
				self.serverTimeSyncTimer = null;
			}
		},

		fetchServerTime: flow(function* fetchServerTime(suppressError = true) {
			const api = getEnv(self).api;
			try {
				const data = yield api.serverTime();
				const { timestamp, timezone } = data.attributes;
				self.offset = Date.now() - timestamp * 1000; // timestamp is in seconds for some reason
				self.timezone = timezone;
				return self.serverTime;
			} catch (err) {
				if (!suppressError) {
					fail(self, err);
				} else {
					Sentry.captureException(err);
				}
			}
		})
	}));
