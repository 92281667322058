import { types } from 'mobx-state-tree';
import { guid, validateApi } from 'helpers/utils';
import { dashboardSlugToKa } from 'helpers/toKa';


export const DashboardSection = types
    .model("DashboardSection", {
        forModel: 'DashboardSection',
        id: types.identifier,
        slug: types.string,
        dataType: types.string, // ??
        dataTemplate: types.string,
        itemsToDisplay: types.number, // ??
        totalItems: types.number,
    })
    .views(self => ({
        get type() {
            return self.dataTemplate === 'tv_chans' ? 'channels' : 'shows';
        },

        get title() {
            return dashboardSlugToKa(self.slug);
        }
    }));


export const fromApi = data => {
    const a = data.attributes;

    const struct = {
        id: guid('DashboardSection::'),
        slug: a.title,
        dataType: a.data_type,
        dataTemplate: a.data_template,
        itemsToDisplay: a.items_to_display,
        totalItems: a.total_elements,
        items: []
    };

    return validateApi(DashboardSection, struct)
};
