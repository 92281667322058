import { types } from 'mobx-state-tree';

export const IdleCounter = types
	.model('IdleCounter', {
		secs: types.optional(types.number, 0)
	})
	.views(self => ({
		get tensecs() {
			return Math.floor(self.secs / 10);
		},

		get halfmins() {
			return Math.floor(self.secs / 30);
		},

		get mins() {
			return Math.floor(self.secs / 60);
		}
	}))
	.actions(self => {
		let timer;

		return {
			afterCreate() {
				self.reset();
				if (!timer) {
					timer = setInterval(self.inc, 1000);
				}
			},

			inc() {
				self.secs++;
			},

			reset() {
				self.secs = 0;
			},

			beforeDestroy() {
				if (timer) {
					clearInterval(timer);
				}
			}
		};
	});
