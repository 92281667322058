
const months = [
    "იანვარი",
    "თებერვალი",
    "მარტი",
    "აპრილი",
    "მაისი",
    "ივნისი",
    "ივლისი",
    "აგვისტო",
    "სექტემბერი",
    "ოქტომბერი",
    "ნოემბერი",
    "დეკემბერი"
];

const weekdays = [
    "ორშაბათი",
    "სამშაბათი",
    "ოთხშაბათი",
    "ხუთშაბათი",
    "პარასკევი",
    "შაბათი",
    "კვირა"
];

const dashboardSlugs = {
    tv_shows_geo: "ქართული გადაცემები",
    featured_videos: "რჩეული ვიდეოები",
    tv_shows_sciense: "საგანმანათლებლო გადაცემები", // science is misspelled I know
    tv_channels: "სატელევიზიო არხები",
    tv_shows_series: "სერიალები",
    tv_shows_movies: "მხატვრული ფილმები",
    tv_shows_sport: "სპორტი"
};

const safePick = (map, num) => map[Math.min(Math.max(0, num - 1), map.length - 1)];

export const monthToKa = num => safePick(months, parseInt(num, 10));
export const weekdayToKa = num => safePick(weekdays, parseInt(num, 10));

export const dashboardSlugToKa = slug => dashboardSlugs[slug] || slug;
