import { types } from 'mobx-state-tree';
import { get, validateApi } from 'helpers/utils';

const PackagePrice = types
    .model('PackagePrice', {
        id: types.number,
        price: types.number,
        days: types.number
    })

export const Package = types
    .model('Package', {
        id: types.string,
        name: types.string, // maybe this could be enumeration
        status: types.optional(types.string, 'disabled'), // ... this too
        description: types.maybe(types.string),
        type: types.string,
        visibility: types.optional(types.string, 'hidden'),
        regionAllowed: types.optional(types.string, 'local'),
        autoRenewalUniqCat: types.number, // ???
        autoRenewalPriority:  types.number, // ???
        prices: types.array(PackagePrice)
    });


export const fromApi = data => {
    const { id, attributes: a = {}, relationships: r = {} } = data;
    const prices = get(r, 'prices.data', [])
        .map(data => {
            const { id, attributes: a = {} } = data;
            return validateApi(PackagePrice, {
                id,
                price: parseFloat(a.price),
                days: a.days
            })
        })
        .map(data => PackagePrice.create(data));

    const struct = {
        id,
        name: a.name,
        status: a.status,
        description: a.description,
        type: a.type,
        regionAllowed: a.regionAllowed,
        autoRenewalUniqCat: a.autoRenewalUniqCat,
        autoRenewalPriority: a.autoRenewalPriority,
        prices
    };

    return validateApi(Package, struct);
}

