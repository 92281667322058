import { types, getEnv, flow, getParentOfType } from 'mobx-state-tree';
import { get } from 'helpers/utils';
import { DashboardSection, fromApi as dashboardSectionFromApi } from './dashboard/DashboardSection';
import { User } from '../User';
import { ChannelSection } from './dashboard/ChannelSection';
import { fail } from '../ModelUtils';

const toType = snapshot => {
    switch(snapshot.forModel) {
        case 'ChannelSection': return ChannelSection;
        case 'DashboardSection':
        default:
            return DashboardSection;
    }
}

export const Dashboard = types
    .model("Dashboard", {
        forModel: 'Dashboard',
        state: types.optional(types.enumeration('State', ['idle', 'pending', 'done', 'error']), 'idle'),
        items: types.optional(types.array(types.union({ dispatcher: toType }, DashboardSection, ChannelSection)), [])
    })
    .views(self => ({
        is: (...args) => args.indexOf(self.state) !== -1,

        get populatedItems() {
            return self.items ? self.items.filter(item => !!item.items) : null;
        }
    }))
    .actions(self => ({
        afterAttach: flow(function* afterAttach() {
            if (self.is('pending')) {
                self.state = 'idle';
            }

            if (!self.items.length) {
                yield self.fetchDashboard();
            }
        }),

        fetchDashboard: flow(function* fetchDashboard() {
            const api = getEnv(self).api;

            if (!self.is('pending')) {
                self.state = 'pending';

                try {
                    const items = yield api.dashboardMain()
                        .then(data => data.filter(({ type }) => type === 'DashboardSection'))
                        .then(data => data.map(item => {
                            const userData = getParentOfType(self, User);
                            const items = get(item, 'relationships.items.data', []);
                            const section = dashboardSectionFromApi(item);

                            switch (section.dataTemplate) {
                                case 'tv_chans':
                                    items.forEach(({ id }) => {
                                        const channel = userData.channels.get(['Channel', id].join('::'));
                                        if (channel) {
                                            section.items.push(channel);
                                        }
                                    })
                                    return ChannelSection.create(section);

                                case 'tv_prog':
                                default:
                            }
                            return DashboardSection.create(section);
                        }));

                    self.items = items;
                    self.state = 'done';
                } catch(ex) {
                    self.state = 'idle';
                    self.items = [];
                    fail(self, ex);
                    return;
                }
            }
        })
    }))
