import { types as t, flow, getRoot, addDisposer } from 'mobx-state-tree';
import { reaction } from 'mobx';
import { isNumber } from 'lodash';
import { Channel } from '../../pages/tv/Channel';
import { fail } from '../../ModelUtils';

export const ChannelStore = t
	.model('ChannelStore', {
		id: t.optional(t.identifier, 'TvChannelStore'),
		selected: t.maybeNull(t.reference(Channel)),
		_items: t.maybe(t.array(t.reference(Channel))),
		order: t.optional(t.array(t.string), [])
	})
	.views(self => ({
		is: (...args) => args.indexOf(self.state) !== -1,

		get root() {
			return getRoot(self);
		},

		get items() {
			// should take into account favorites and their order
			const favOffset = self.order.length;
			const orderDict = self.order.reduce((dict, key, idx) => {
				dict.set(key, idx);
				return dict;
			}, new Map());
			return self._items
				? self._items.sort((a, b) => {
						const _1 = orderDict.has(a.key)
							? orderDict.get(a.key)
							: favOffset + a.sort;
						const _2 = orderDict.has(b.key)
							? orderDict.get(b.key)
							: favOffset + b.sort;
						return _1 - _2;
				  })
				: null;
		},

		get first() {
			return self.items && self.items.length ? self.items[0] : null; // TODO when favorites finally get functional, change this to the first favorite
		},

		get indexOfSelected() {
			return self.selected
				? self.items.findIndex(({ key }) => self.selected.key === key)
				: -1;
		},

		isSeekingTooLong() {
			return !self.lastSeekOn || Date.now() - self.lastSeekOn > 1000 * 60;
		},

		canSeek() {
			return (
				(!self.isSeeking || self.isSeekingTooLong()) &&
				(!self.selected || self.selected.canSeek())
			);
		},

		isSelected(channel) {
			return !!(self.selected && channel && self.selected.id === channel.id);
		},

		isFavorite(channel) {
			const key = typeof channel === 'string' ? channel : channel.key;
			return self.order.indexOf(key) !== -1;
		},

		findChannelById(id) {
			return self.items ? self.items.find(item => item.id === id) : null;
		},

		findChannelByKey(key) {
			// we could also take it directly from global channel map
			return self.items ? self.items.find(item => item.key === key) : null;
		},

		getPrevWatchableChannel(idx) {
			const newIdx = idx > 0 ? idx - 1 : self._items.length - 1;
			const channel = self.items[newIdx];
			return channel && channel.canWatch
				? channel
				: self.getPrevWatchableChannel(newIdx);
		},

		getNextWatchableChannel(idx) {
			const newIdx = idx < self._items.length - 1 ? idx + 1 : 0;
			const channel = self.items[newIdx];
			return channel && channel.canWatch
				? channel
				: self.getNextWatchableChannel(newIdx);
		},

		filterBy(predicate) {
			const items = [];
			self._items.forEach(channel => {
				if (predicate(channel) === true) {
					items.push(channel);
				}
			});
			return items;
		}
	}))
	.volatile(self => ({
		// this has to be separate, otherwise select action manages to sleep through the
		// gap between async invokations
		isSeeking: false,
		lastSeekOn: null
	}))
	.actions(self => ({
		afterAttach() {
			addDisposer(
				self,
				reaction(
					() => self.root.channels,
					channels => {
						if (channels) {
							self.refreshChannels(channels);
						} else {
							self.beforeDestroy();
						}
					}
				)
			);

			self.refreshChannels(self.root.channels || []);
		},

		refreshChannels(channels) {
			if (!channels) {
				return;
			}

			const items = [];
			// purpose of posttv channels is unclear and they never work so filter them out for now
			channels.forEach((channel, key) => {
				if (channel.type === 'tv' && !/^posttv/.test(channel.slug)) {
					items.push(channel);
				}
			});
			self._items = items;
			self.selected = items.length ? items[0] : null;
		},

		beforeDestroy() {
			self.selected = null;
			self._items = [];
		},

		setSeeking(state) {
			self.isSeeking = state;
			if (!state) {
				self.lastSeekOn = Date.now();
			}
		},

		select: flow(function* select(item, time = null) {
			if (isNumber(item)) {
				item = self.items[item];
			}

			if (!item) {
				if (self.first) {
					item = self.first;
				} else if (self.root.channels && self.root.channels.length) {
					self.refreshChannels(self.root.channels);
					return;
				} else {
					fail(self, new Error(`არხების ჩამონათვალი ჯერ არ ჩამოიტვირთა.`));
					return null;
				}
			}

			const shouldSelect = !self.isSelected(item) || item.isTimeToUpdateChunk();
			if (shouldSelect) {
				if (!self.canSeek()) {
					fail(self, new Error(`მიდიაპლეიერი ოპერაციის შესრულების პროცესშია.`));
					return null;
				}
				self.setSeeking(true);

				let chunk;

				if (!time || !item.hasRecordingForTime(time)) {
					if (item.hasLiveStream) {
						chunk = yield item.goLive();
					} else if (item.hasPrograms) {
						chunk = yield item.seekTo(item.programStore.last.actualStartTime);
					} else {
						yield item.fetchPrograms();
						self.setSeeking(false);
						return yield self.select(item);
					}
				} else {
					chunk = yield item.seekTo(+time);
				}

				self.setSeeking(false);

				if (chunk) {
					self.selected = item;
				} else {
					return null;
				}
			}
			return self.selected;
		}),

		selectPrev: flow(function* selectPrev() {
			const channel = self.getPrevWatchableChannel(self.indexOfSelected);
			return yield self.select(channel);
		}),

		selectNext: flow(function* selectNext() {
			const channel = self.getNextWatchableChannel(self.indexOfSelected);
			return yield self.select(channel);
		}),

		addToFavorites(channel) {
			const { key } = channel;
			if (!self.order.indexOf(key) !== -1) {
				self.order.unshift(key);
			}
		},

		removeFromFavorites(channel) {
			const key = typeof channel === 'string' ? channel : channel.key;
			const idx = self.order.indexOf(key);
			if (idx !== -1) {
				self.order.splice(idx, 1);
			}
		},

		toggleFavorite(channel) {
			self.isFavorite(channel)
				? self.removeFromFavorites(channel)
				: self.addToFavorites(channel);
		},

		clearFavorites() {
			self.order = [];
		},

		setOrder(order) {
			self.order = order;
		}
	}));
