import { assign, isNumber } from 'lodash';
import moment from 'helpers/moment';
import { ApiClient, ApiError } from './ApiClient';

export class MyVideoApi extends ApiClient {
	get hasClientCredentials() {
		return !!(this._accessToken && this._refreshToken);
	}

	get hasUserCredentials() {
		return !!(this._username && this._password);
	}

	get isAuthorized() {
		return !!(this._accessToken || this._refreshToken);
	}

	get isGuest() {
		return this.isAuthorized && !this.isUser;
	}

	get isUser() {
		return (
			this.isAuthorized &&
			(this.hasUserCredentials || this.hasClientCredentials)
		);
	}

	constructor(cfg) {
		super(cfg);

		this._username = null;
		this._password = null;
		this._accessToken = null;
		this._refreshToken = null;

		this._defaultOpts = {
			baseUrl: dotenv.REACT_APP_API_BASEURL,
			method: 'get',
			requiresAuth: true
		};
	}

	setAccessToken(value) {
		this.setAuthHeader((this._accessToken = value));
	}

	setRefreshToken(value) {
		this._refreshToken = value;
	}

	setUserCredentials(authData = {}, suppressCallback = true) {
		const { username, password, accessToken, refreshToken } = authData;
		this._username = username;
		this._password = password;
		this.setTokens(accessToken, refreshToken, suppressCallback);

		if (!suppressCallback && typeof this._cfg.onAuthUpdate === 'function') {
			this._cfg.onAuthUpdate(authData);
		}
	}

	setTokens(accessToken, refreshToken, suppressCallback = true) {
		this.setAccessToken(accessToken);
		this.setRefreshToken(refreshToken);

		if (!suppressCallback && typeof this._cfg.onTokensUpdate === 'function') {
			this._cfg.onTokensUpdate({
				accessToken,
				refreshToken
			});
		}
	}

	setAuthHeader(accessToken) {
		this._defaultAuthHeaders.Authorization = `Bearer ${accessToken}`;
	}

	async authToken(params) {
		const res = await super._api(
			{
				endpoint: '/auth/token',
				method: 'post',
				requiresAuth: false
			},
			assign({ client_id: this._cfg.clientId }, params)
		);
		// at the moment authorizing with username/password is the only proper way
		// to authorize user on a smartv - here we let the ApiClient to memorize user
		// credentials to automatically reauthenticate when possible
		// TODO: instead of keeping password in memory ApiClient can be given secure
		// password getter, but it doesn't matter at the moment
		if (params.username && params.password) {
			this._username = params.username;
			this._password = params.password;

			this.setUserCredentials(
				{
					username: this._username,
					password: this._password,
					accessToken: res.access_token,
					refreshToken: res.refresh_token
				},
				false
			);
		} else {
			this.setTokens(res.access_token, res.refresh_token, false);
		}

		return res;
	}

	async authClient() {
		const res = await this.authToken({
			grant_type: 'client_credentials',
			client_secret: this._cfg.apiSecret
		});

		this._grantType = 'client_credentials';
		return res;
	}

	async authGuest() {
		const res = await this.authToken({
			grant_type: 'client_implicit',
			client_id: this._cfg.clientId
		});

		this._grantType = 'client_implicit';
		return res;
	}

	async authUser(username, password) {
		const res = this.authToken({
			grant_type: 'implicit',
			username,
			password
		});

		this._grantType = 'implicit';
		return res;
	}

	async reAuthUser() {
		return this._username && this._password
			? this.authUser(this._username, this._password)
			: Promise.reject(new ApiError(ApiError.USER_NOT_AUTHORIZED));
	}

	async refreshToken(refreshToken) {
		if (!this._locked) {
			this._locked = true;
		}

		const res = await super._api(
			{
				endpoint: '/auth/refresh-token',
				method: 'post',
				requiresAuth: false
			},
			{
				client_id: this._cfg.clientId,
				client_secret: this._cfg.apiSecret, // should not be required, but is at the moment :P
				grant_type: 'refresh_token_implicit',
				refresh_token: refreshToken
			}
		);

		this._locked = false;

		if (this._username && this._password) {
			this.setUserCredentials(
				{
					username: this._username,
					password: this._password,
					accessToken: res.access_token,
					refreshToken: res.refresh_token
				},
				false
			);
		} else {
			this.setTokens(res.access_token, res.refresh_token, false);
		}

		setImmediate(() => {
			this._process();
		});

		return res;
	}

	async logout() {
		return super
			._api(
				{
					endpoint: '/auth/token',
					method: 'patch',
					requiresAuth: false
				},
				{},
				{
					// to logout you need to be logged in, but we do not want to go through lock
					// resolution flow, so provide Authorization header manually
					Authorization: `Bearer ${this._accessToken}`
				}
			)
			.finally(() => {
				this._lockReset();
				delete this._defaultAuthHeaders.Authorization;
				this.setUserCredentials({});
			});
	}

	async serverTime() {
		return this._api('/applicationinfo/server-time');
	}

	async user() {
		return this._api('/auth/user');
	}

	async balance(userId) {
		return this._api(`/payment/balance/${userId}`);
	}

	async services() {
		return this._api('/services');
	}

	async channels(type = null) {
		return this._api('/channel', type ? { type } : {});
	}

	async channelChunk(channel, dateTime = null, center = false) {
		const params = {};
		if (center) {
			params.center = true;
		}
		if (dateTime) {
			params.datetime = isNumber(dateTime)
				? moment(dateTime).format('YYYY-MM-DD HH:mm:ss')
				: dateTime;
		}
		return this._api(`/channel/chunk/${channel}`, params);
	}

	async dvrGaps(channel, startTime, endTime) {
		return this._api(`channel/${channel}/dvr-gaps`, {
			from: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
			to: moment(endTime).format('YYYY-MM-DD HH:mm:ss')
		});
	}

	async dashboardMain() {
		return this._api('/dashboard/mobile/main');
	}

	async programs(channelId, startTime, endTime) {
		const params = {
			channelId,
			shift: 'disabled' // if enabled, returns program from 6:00 till 6:00 of the next day (which we do not want)
		};

		if (startTime && endTime) {
			params.startDate = moment(startTime).format('YYYY-MM-DD');
			params.endDate = moment(endTime).format('YYYY-MM-DD');
		}

		return this._api('/programs', params);
	}

	async searchPrograms(
		keyword,
		offset = 0,
		limit = 50,
		channelIds = [],
		presentOnly = true
	) {
		return super._api('/programs/search', {
			keyword,
			offset,
			limit,
			channelIds: channelIds.join(','),
			presentOnly
		});
	}

	async _api() {
		return super._api.apply(this, arguments).then(res => res.data);
	}

	async _resolveLock() {
		if (this.isUser) {
			if (this._refreshToken) {
				return this.refreshToken(this._refreshToken)
					.catch(() => this.reAuthUser())
					.catch(() => this.authGuest());
			} else {
				return this.reAuthUser().catch(() => this.authGuest());
			}
		} else {
			return this.authGuest();
		}
	}
}
