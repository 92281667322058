import { Auth } from './models/Auth';
import { MyVideoApi } from './api/MyVideoApi';
import Env from './Env';

let initialState = { version: Env.version };

const api = new MyVideoApi({
	clientId: dotenv.REACT_APP_API_CLIENT_ID,
	apiSecret: dotenv.REACT_APP_API_CLIENT_SECRET
});

if (localStorage.getItem('myStore')) {
	const snapshot = JSON.parse(localStorage.getItem('myStore'));
	//typecheck(Auth, snapshot);
	if (Auth.is(snapshot)) {
		initialState = snapshot;
	} else {
		localStorage.removeItem('myStore'); // invalidate stored snapshot
	}
}

export default Auth.create(initialState, { api });

import('./main');
