import { types, getEnv, getParent, getRoot, flow } from 'mobx-state-tree';
import moment from 'helpers/moment';
import { assign, isUndefined, sortedUniqBy } from 'lodash';
import { Program, fromApi as programFromApi } from './Program';
import { fail } from '../../ModelUtils';

export const ProgramStore = types
	.model('ProgramStore', {
		timestamp: types.optional(types.number, () => +new Date()),
		state: types.optional(
			types.enumeration('State', ['idle', 'pending', 'done', 'error']),
			'idle'
		),
		error: types.maybe(types.string),
		items: types.maybe(types.array(types.late(() => Program)))
	})
	.views(self => ({
		is: (...args) => args.indexOf(self.state) !== -1,

		get isLoaded() {
			return !!(self.items && self.items.length);
		},

		isActual() {
			return self.isLoaded && moment().diff(self.timestamp, 'hours', true) < 2;
		},

		get first() {
			return self.isLoaded ? self.items[0] : null;
		},

		get last() {
			return self.isLoaded ? self.items[self.items.length - 1] : null;
		},

		get root() {
			return getRoot(self);
		},

		serverTime() {
			return getRoot(self).serverTime.now();
		},

		get startTime() {
			return self.items ? self.items[0].startTime : null;
		},

		get endTime() {
			return self.items ? self.items[self.items.length - 1].finishTime : null;
		},

		get channel() {
			return getParent(self);
		},

		get playableFinishedItems() {
			return self.items
				? self.items.filter(item => !item.isDisabled && !item.isLive)
				: null;
		},

		getProgramByTime(time) {
			return self.isLoaded
				? self.items.find(({ startTime, finishTime }) =>
						moment(time).isBetween(startTime, finishTime, null, '[)')
				  )
				: null;
		},

		getProgramsForDay(time) {
			let idx = self.findIndexForDay(time, true);

			if (idx < 0 || !self.isLoaded) {
				return null;
			} else {
				const programs = [];
				const startDay = moment(time).startOf('day');
				let nextProgram, nextDay;
				for (; idx < self.items.length; idx++) {
					nextProgram = self.items[idx];
					nextDay = moment(nextProgram.startTime).startOf('day');

					if (startDay.isSame(nextDay)) {
						programs.push(nextProgram);
					} else {
						break;
					}
				}
				return programs.length ? programs : null;
			}
		},

		getFirstProgramForDay(time) {
			const programs = self.getProgramsForDay(time);
			return programs ? programs[0] : null;
		},

		getLastProgramForDay(time) {
			const programs = self.getProgramsForDay(time);
			return programs ? programs[programs.length - 1] : null;
		},

		hasProgramsForDay(time) {
			return self.findIndexForDay(time, true) > -1;
		},

		groupedByDay() {
			let prevDate;
			let bag = [];
			let items = [];

			if (self.isLoaded) {
				// we assume here that programs are sorted in ascending order (TODO actually sort before adding to the store)
				self.items.forEach((item, idx, arr) => {
					const isLastItem = idx === arr.length - 1;
					let date = moment(item.startTime).startOf('day');

					// eslint-disable-next-line
					if ((prevDate && prevDate.isBefore(date)) || isLastItem) {
						bag.push({
							date: prevDate.valueOf(),
							day: prevDate.date(), // why not to just make it day()?
							month: prevDate.format('MMMM'),
							weekday: prevDate.format('dddd'),
							items: items.slice()
						});
						items = []; // reset
					} else {
						items.push(item);
					}

					prevDate = date;
				});
			}
			return bag;
		},

		findIndex(program) {
			// indexOf doesn't find an item (probably a clone or something)
			return self.isLoaded
				? self.items.findIndex(item => item.id === program.id)
				: -1;
		},

		findIndexByTime(time) {
			return self.isLoaded
				? self.items.findIndex(({ startTime, finishTime }) =>
						moment(time).isBetween(startTime, finishTime, null, '[)')
				  )
				: -1;
		},

		findIndexForDay(time, containedWithin = false) {
			if (self.isLoaded) {
				for (let i = 0; i < self.items.length; i++) {
					let { startTime, finishTime } = self.items[i];
					let startsOnSameDay = moment(startTime).isSame(time, 'day');
					let finishesOnSameDay = moment(finishTime).isSame(time, 'day');
					if (
						// eslint-disable-next-line
						(containedWithin && startsOnSameDay && finishesOnSameDay) ||
						(!containedWithin && (startsOnSameDay || finishesOnSameDay))
					) {
						return i;
					}
				}
			}
			return -1;
		},

		getPrevTo(program) {
			const idx = self.findIndex(program);
			return idx > -1 ? self.items[idx - 1] : null;
		},

		getNextTo(program) {
			const idx = self.findIndex(program);
			return idx > -1 && idx < self.items.length - 1
				? self.items[idx + 1]
				: null;
		}
	}))
	.actions(self => ({
		afterAttach() {
			if (self.is('pending')) {
				self.state = 'idle';
			}
		},

		fetchPrograms: flow(function* fetchPrograms(
			startTime,
			endTime,
			replacePrograms = false
		) {
			const api = getEnv(self).api;

			if (!self.is('pending')) {
				const defaultStartTime = moment(self.serverTime())
					.subtract(1, 'day')
					.valueOf();
				const defaultEndTime = moment(self.serverTime())
					.add(1, 'day')
					.valueOf();

				self.state = 'pending';

				let data;

				try {
					data = yield api
						.programs(
							self.channel.id,
							startTime || defaultStartTime,
							endTime || defaultEndTime
						)
						.then(fromApi);
				} catch (ex) {
					self.state = 'idle';
					fail(self, ex);
					return;
				}

				self.timestamp = self.serverTime();

				// fully replace only if both startTime and endTime weren't specified
				// eslint-disable-next-line
				if (
					(isUndefined(startTime) && isUndefined(endTime)) ||
					replacePrograms
				) {
					assign(self, data);
				} else {
					// ...update instead of replacing
					const items = data.items;
					const cachedItems = self.items ? self.items.slice() : [];
					items.push.apply(items, cachedItems);

					// re-sort and leave only uinque items
					items.sort((a, b) => a.startTime - b.startTime);
					assign(self, {
						state: 'done',
						items: sortedUniqBy(items, ({ startTime }) => startTime)
					});
				}
				return data;
			}
		})
	}));

export const fromApi = data => {
	const items = data
		// not sure if any other types are possible, but filter out just in case
		.filter(({ type }) => type === 'Program')
		.map(item => Program.create(programFromApi(item)));

	if (items.length) {
		items.sort((a, b) => a.startTime - b.startTime);
	}

	return {
		items,
		state: 'done'
	};
};
